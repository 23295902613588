@import "vars";
@import "mixins";

.view-ext-btn {
  color: #6B59F8;
  border-bottom: 1px dotted $color-primary;
  cursor: pointer;

  &:hover {
    opacity: .65;
  }
}

.i18n-h1 {
  font-family: Poppins;
  font-weight: 700;
  color: $color-primary;
}

.heading {
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;

  @include b-md {
    font-size: 20px;
    line-height: 28px;
  }
}

.subheading {
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  @include b-lg {
    font-size: 18px;
    line-height: 24px;
  }
}

.description {
  font-size: 15px;
  line-height: 24px;
  color: #5F6D7E;

  @include b-lg {
    font-size: 14px;
  }
}

.text-primary {
  color: $color-primary;
}

.text-uppercase {
  text-transform: uppercase;
}
