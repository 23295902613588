@import "hostize-icons";
@import "open-sans";
@import "neometric";

*:not(.hi) {
  font-family: 'Open Sans', sans-serif;
}

.hi-spinner {
  animation: 1s spin-animation linear infinite;
}

@keyframes spin-animation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
