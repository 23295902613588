@import "mixins";

* {
  box-sizing: border-box;
}

:root {
  --header-height: 64px;

  @include b-md {
    --header-height: 64px;
  }
}

html {
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
  color: #212529;
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;

  body {
    padding: 0;
    margin: 0;
  }
}

h1, h2, h3, h4, h5, h6, ul, p {
  margin: 0;
}

.typed .caret {
  border-right: .5rem #555 solid;
  animation: caret-anim 1s steps(1) infinite;

  @keyframes caret-anim {
    50% {
      border-color: transparent;
    }
  }
}

.rtl {
  direction: rtl;
}

.markdown {
  h1, h2, h3, h4, h5, h6, ul, p {
    margin-bottom: 36px;
  }
}
