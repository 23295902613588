@font-face {
  font-family: 'hostize-icons';
  src: url('./hostize-icons.woff2?asdasd') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.hi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hostize-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hi-chatize:before {
  content: "\e906";
}
.hi-book-open:before {
  content: "\e907";
}
.hi-calendar:before {
  content: "\e908";
}
.hi-chevron-left:before {
  content: "\e909";
}
.hi-chevron-right:before {
  content: "\e90a";
}
.hi-download-cloud:before {
  content: "\e90b";
}
.hi-eye:before {
  content: "\e905";
}
.hi-globe:before {
  content: "\e910";
}
.hi-log-in:before {
  content: "\e911";
}
.hi-log-out:before {
  content: "\e912";
}
.hi-user:before {
  content: "\e913";
}
.hi-x:before {
  content: "\e904";
}
.hi-menu1:before {
  content: "\e903";
}
.hi-search:before {
  content: "\e901";
}
.hi-envelop:before {
  content: "\e90c";
}
.hi-facebook:before {
  content: "\e90d";
}
.hi-twitter:before {
  content: "\e90e";
}
.hi-info:before {
  content: "\e90f";
}
.hi-arrow-down:before {
  content: "\e902";
}
.hi-spinner:before {
  content: "\e900";
}
