@import "vars";

@mixin link-styles {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

a {
  text-decoration: none;
  color: unset;

  &.highlight {
    color: #3176d9;
  }
}

.link {
  color: #272D37;

  &:hover {
    color: $color-primary;
    //@include link-styles;
  }
}


