@import "mixins";

.container {
  max-width: 1400px;
  width: 100%;
  display: block;
  margin: 0 auto;

  @include b-xl {
    padding: 0 10%;
  }

  @include b-sm {
    padding: 0 24px;
  }
}
