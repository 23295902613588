@mixin b-xs {
  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin b-sm {
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin b-md {
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin b-lg {
  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin b-xl {
  // X-Large devices (large desktops, less than 1400px)
  @media (max-width: 1487.98px) {
    @content;
  }
}
