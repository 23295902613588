@import "vars";
@import "mixins";

.btn {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border: none;
  outline: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 9px 16px;
  cursor: pointer;
  transition: .25s;

  &-sm {
    min-height: 34px;
    padding: 6px 12px;
  }

  @include b-md {
    min-height: 34px;
    padding: 6px 12px;
  }

  &-primary {
    background-color: $color-primary;
    color: #fff;

    &:hover {
      background-color: $color-primary-hover;
    }

    &:active {
      background-color: $color-primary-active;
    }
  }

  &-danger {
    background-color: $color-danger;
    color: #fff;

    &:hover {
      background-color: $color-danger-hover;
    }

    &:active {
      background-color: $color-danger-active;
    }
  }

  &-get-started {
    background-color: #715EF8;
    color: #fff;

    &:hover {
      background-color: $color-primary-hover;
    }

    &:active {
      background-color: $color-primary-active;
    }
  }

  &-outline-primary {
    background-color: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;

    &:hover {
      background-color: $color-primary-hover;
      color: #fff;
    }

    &:active {
      background-color: $color-primary-active;
    }
  }

  &-outline-danger {
    background: none;
    border: 1px solid #dc3545;
    color: #f12626;

    &:hover {
      background-color: #bb2533;
      color: #fff;
    }

    &:active {
      background-color: #911520;
      border-color: #911520;
      color: #fff;
    }
  }

  &:disabled, &.disabled {
    pointer-events: none;
    opacity: .5;
  }
}
