@import "mixins";

$header-height: var(--header-height);

$color-primary: #7C6CF8;
$color-primary-hover: #6658d5;
$color-primary-active: #4d42ab;

$color-danger: #dc3545;
$color-danger-hover: #be2332;
$color-danger-active: #8f121e;
